import React from 'react';
import { Container, DropdownButton, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { hasRole } from '../../Auth';
import useToken from '../App/useToken';
import logo from './images/rotulo.png';

const NavbarBootstrap = () => {
  const { token, setToken } = useToken();
  const clearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Navbar bg="dark" expand="xl" sticky="top" style={{ minHeight: "5em" }}>
      <Container style={{ fontSize: "20px" }}>
        <Navbar.Brand as={Link} to="/search"><img src={logo} alt="LogoDef" style={{
          width: "400px",
          height: "87px",
          objectFit: "contain",
          padding: "0.75rem",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto"
        }} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ color: "white" }} />
        <Navbar.Collapse id="basic-navbar-nav" style={{ color: "white" }}>
          <Nav style={{ marginLeft: "auto", color: "white" }}>
            <Nav.Link style={{ color: "white" }} as={Link} to="/search">Búsqueda</Nav.Link>
            <Nav.Link style={{ color: "white" }} as={Link} to="/create">Crear</Nav.Link>
            <Nav.Link style={{ color: "white" }} as={Link} to="/shipment-update">Editar</Nav.Link>
            <Nav.Link style={{ color: "white" }} as={Link} to="/list">Listado</Nav.Link>
            <Nav.Link style={{ color: "white" }} as={Link} to="/tracking-update">Tracking</Nav.Link>
            {hasRole(token, ['admin']) && <DropdownButton title="ECOSERVICIOS" id="basic-nav-dropdown" style={{ marginTop: "0.2em", paddingLeft: "2em" }}>
              <NavDropdown.Item as={Link} to="/client">Clientes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/user">Usuarios</NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="#">Provincias y Cantónes</NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/list-ecoservicios">Listado de envíos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tracking-list">Crear Track</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => clearLocalStorage()}>Cerrar Sesión</NavDropdown.Item>
            </DropdownButton>}
            {hasRole(token, ['user']) && <Button variant="danger" style={{ marginTop: "0.2em", marginLeft: "2em" }} onClick={() => clearLocalStorage()}>
              Cerrar Sesión
            </Button>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavbarBootstrap;
