import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { hasRole } from '../../Auth';
import useToken from '../../components/App/useToken';
import './styles.css';
import styles from './Tracking.module.scss';

const API = process.env.REACT_APP_API;

export const UpdateTracking = () => {
  const { token, setToken } = useToken();
  const [guideNumberTracking, setGuideNumberTracking] = useState('');
  const [broadcastDate, setBroadcastDate] = useState('');
  const [addressee, setAddressee] = useState('');
  const [city, setCity] = useState('');
  const [content, setContent] = useState('');
  const [sendDate, setSendDate] = useState('');
  const [destinationArrivalDate, setDestinationArrivalDate] = useState(null);
  const [recipientDeliveryDate, setRecipientDeliveryDate] = useState(null);
  const [receivedBy, setReceivedBy] = useState(null);
  const [identification, setIdentification] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [clientId, setClientId] = useState('');

  const [trackingList, setTrackingList] = useState([]);
  const [guideNumber, setGuideNumber] = useState('');
  const [images, setImages] = useState([]);

  const searchByGuideNumber = async (e) => {
    if (guideNumber === '') {
      e.preventDefault();
      getTrackingList(token.client?.id ?? clientId);
    } else {
      e.preventDefault();
      const response = await fetch(`${API}/tracking/guide/${guideNumber}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      setTrackingList(data);
      setGuideNumber('');
    }
  }

  const getTrackingList = async (clientId) => {
    const response = await fetch(`${API}/tracking?clientId=${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setTrackingList(data);
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const clientList = data.map((client) => { return { id: client.id, value: client.name } })
    setClientList(clientList);
  }

  const seeTracking = async (id) => {
    const response = await fetch(`${API}/tracking/${id}`);
    const data = await response.json();
    setGuideNumberTracking(data.shipment.guideNumber);
    setBroadcastDate(data.shipment.receptionsDate);
    setAddressee(data.shipment.receiver);
    setCity(data.shipment.city);
    setContent(data.shipment.contains);
    setSendDate(data.receiverSendDate);
    setDestinationArrivalDate(data.arriveDate);
    setRecipientDeliveryDate(data.deliveryDate);
    setReceivedBy(data.receivedBy);
    setIdentification(data.identification);
    setImages(data.images ?? []);
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const renderPhotos = (source) => {
    console.log("source: ", source);
    return source.map((photo) => {
      return <img src={photo} alt="" key={photo} />;
    });
  };

  useEffect(() => {
    if (hasRole(token, ['user'])) {
      getTrackingList(token.client.id);
    } else {
      getClients();
    }
  }, [])

  return (
    <div className={styles.tracking}>
      <h2>Información Tracking</h2>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div class="col-sm-4">
          <label>Nro guía</label>
          <input
            type="text"
            value={guideNumberTracking}
            className="form-control"
            disabled
            autoFocus
          />
        </div>
        <div class="col-sm-4">
          <label>Fecha emisión</label>
          <input
            type="text"
            value={broadcastDate}
            className="form-control"
            disabled
          />
        </div>
        <div class="col-sm-4">
          <label>Destinatario</label>
          <input
            type="text"
            value={addressee}
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div class="col-sm-4">
          <label>Ciudad</label>
          <input
            type="text"
            value={city}
            className="form-control"
            disabled
          />
        </div>
        <div class="col-sm-4">
          <label>Contenido</label>
          <input
            type="text"
            value={content}
            className="form-control"
            disabled
          />
        </div>
        <div class="col-sm-4">
          <label>Identificación de quien recibe</label>
          <input
            type="text"
            value={identification}
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div class="col-sm-4">
          <label>Fecha envío</label>
          <input
            type="text"
            value={sendDate}
            className="form-control"
            placeholder="Fecha envío destino"
            disabled
          />
        </div>
        <div class="col-sm-4">
          <label>Fecha llegada (destino)</label>
          <input
            type="text"
            value={destinationArrivalDate}
            className="form-control"
            disabled
          />
        </div>
        <div class="col-sm-4">
          <label>Fecha entrega (destinatario)</label>
          <input
            type="text"
            value={recipientDeliveryDate}
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div class="col-sm-4">
          <label>Recibido por</label>
          <input
            type="text"
            value={receivedBy}
            className="form-control"
            disabled
          />
        </div>
      </div>
      <div>
        <h2>Fotos</h2>
        <div className="result">{renderPhotos(images)}</div>
      </div>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div style={{ margin: "auto", padding: "10px" }} className="col-md-4">
          <form onSubmit={searchByGuideNumber} className="card card-body" onKeyDown={e => { handleKeyDown(e, searchByGuideNumber) }}>
            <h3 style={{ marginTop: "0.5em" }} align="center">Buscar tracking</h3>
            <input
              type="text"
              onChange={e => setGuideNumber(e.target.value)}
              value={guideNumber}
              className="form-control"
              placeholder="Número de guía (opcional)"
            />
            {hasRole(token, ['admin']) && <div style={{ marginTop: "0.5em", textAlign: "center", display: "block" }}>
                <label>Cliente</label>
                <Autocomplete
                  onChange={(event, newValue) => {
                    // if (!newValue) {
                    //   setCanton(null)
                    // }
                    setClientId(newValue?.id);
                    //getClient(newValue?.id);
                  }}
                  options={clientList}
                  getOptionLabel={option => option.value}
                  style={{ marginTop: "0.3em" }}
                  renderInput={(params) =>
                    <TextField {...params} variant="outlined" />}
                />
              </div>}
            <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
              Buscar
            </button>
          </form>
        </div>
        <h3 style={{ marginTop: "0.5em" }} align="center">Listado de tracking</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Nro Guía</th>
              <th>Fecha emisión</th>
              <th>Destinatario</th>
              <th>Ciudad</th>
              <th>Contenido</th>
              <th>Fecha envío</th>
              <th>Fecha llegada</th>
              <th>Fecha entrega</th>
              <th>Estado</th>
              <th>Operación</th>
            </tr>
          </thead>
          <tbody>
            {trackingList.map(track => (
              <tr key={track.id}>
                <td>{track.shipment.guideNumber}</td>
                <td>{track.shipment.receptionsDate}</td>
                <td>{track.shipment.receiver}</td>
                <td>{track.shipment.city}</td>
                <td>{track.shipment.contains}</td>
                <td>{track.receiverSendDate}</td>
                <td>{track.arriveDate}</td>
                <td>{track.deliveryDate}</td>
                <td>{track.status === 'TRANSIT' ? "Tránsito" : "Entregado"}</td>
                <td>
                  <button
                    className="btn btn-success btn-block"
                    onClick={(e) => seeTracking(track.id)}
                  >
                    Ver
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}