import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';
import { hasRole } from '../../Auth';
import useToken from '../../components/App/useToken';
import FileUpload from '../../components/FileUpload/file-upload.component';
import direccion from './images/direccion.png';
import logo from './images/logoecoDEF.png';
import styles from './Shipment.module.scss';
import './styles.css';

const API = process.env.REACT_APP_API;

export const ShipmentList = () => {
  const { token, setToken } = useToken();
  const [shipments, setShipments] = useState([]);
  const [from, setFrom] = useState(moment().format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
  const [clientId, setClientId] = useState('');

  const [guideNumberTracking, setGuideNumberTracking] = useState('');
  const [broadcastDate, setBroadcastDate] = useState('');
  const [addressee, setAddressee] = useState('');
  const [city, setCity] = useState('');
  const [content, setContent] = useState('');
  const [sendDate, setSendDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [destinationArrivalDate, setDestinationArrivalDate] = useState(null);
  const [recipientDeliveryDate, setRecipientDeliveryDate] = useState(null);
  const [receivedBy, setReceivedBy] = useState(null);
  const [identification, setIdentification] = useState(null);
  const [displayTracking, setDisplayTracking] = useState('none');
  const [displayList, setDisplayList] = useState('block');
  const [trackingId, setTrackingId] = useState('');
  const [edition, setEdition] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const [status, setStatus] = useState('');
  const [creationDate, setCreationDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [clientList, setClientList] = useState([]);

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const pageStyle = `{ size: 1.5in 2in}`;
  const componentRef = useRef();

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: []
  });

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  const ref = React.createRef();

  const getShipments = async (clientId) => {
    const response = await fetch(`${API}/shipment?clientId=${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setShipments(data);
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const clientList = data.map((client) => { return { id: client.id, value: client.name } })
    setClientList(clientList);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (from === '' && to === '') {
      getShipments(token.client?.id ?? clientId);
    } else {
      const response = await fetch(`${API}/shipment/client/by-dates?from=${from}&to=${to}&clientId=${token.client?.id ?? clientId}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      setShipments(data);
      setFrom(moment().format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
      setCreationDate(moment().format('YYYY-MM-DD HH:mm'));
    }
  }

  const editTracking = async (id) => {
    const response = await fetch(`${API}/tracking/guide/${id}`);
    const data = await response.json();
    setDisplayList('none');
    setDisplayTracking('block');
    if (data.length === 0) {
      const shipmentByGuide = await fetch(`${API}/shipment/client/by-guide?guideNumber=${id}`);
      const shipmentData = await shipmentByGuide.json();
      setGuideNumberTracking(id);
      setBroadcastDate(shipmentData.receptionsDate);
      setAddressee(shipmentData.receiver);
      setCity(shipmentData.city);
      setContent(shipmentData.contains);
      setShipmentId(shipmentData.id);
    } else {
      setEdition(true);
      setTrackingId(data[0].id);
      setGuideNumberTracking(data[0].shipment.guideNumber);
      setBroadcastDate(data[0].shipment.receptionsDate);
      setAddressee(data[0].shipment.receiver);
      setCity(data[0].shipment.city);
      setContent(data[0].shipment.contains);
      setSendDate(data[0].receiverSendDate);
      setDestinationArrivalDate(data[0].arriveDate);
      setRecipientDeliveryDate(data[0].deliveryDate);
      setReceivedBy(data[0].receivedBy);
      setIdentification(data[0].identification);
      setStatus(data[0].status);
    }
    console.log(data);
  }

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  };

  const handleUploadImages = () => {
    let newArr = newUserInfo.profileImages;
    for (let i = 0; i < newArr.length; i++) {
      handleUpload(newArr[i]);
    }
  };

  const handleUpload = (file) => {
    let newFileName = file.name.replace(/\..+$/, "");
    console.log("*************************");
    console.log(file);
    // const ReactS3Client = new S3(config);
    // ReactS3Client.uploadFile(file, newFileName).then((data) => {
    //   console.log("dataaaaaaaaaa");
    //   console.log(data);
    //   if (data.status === 204) {
    //     console.log("success");
    //   } else {
    //     console.log("fail");
    //   }
    // });
  };

  const handleSubmitTracking = async (e) => {
    e.preventDefault();
    if (edition) {
      const response = await fetch(`${API}/tracking/${trackingId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          receiverSendDate: sendDate,
          arriveDate: destinationArrivalDate,
          deliveryDate: recipientDeliveryDate,
          receivedBy,
          identification
        })
      });
      if (response.status === 200) {
        swal({
          title: "Tracking actualizado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setGuideNumberTracking('');
        setBroadcastDate('');
        setAddressee('');
        setCity('');
        setContent('');
        setSendDate('');
        setDestinationArrivalDate('');
        setRecipientDeliveryDate('');
        setReceivedBy('');
        setIdentification('');
        setTrackingId('');
        setDisplayList('block');
        setDisplayTracking('none');
      } else {
        swal({
          title: "Ocurrió un error al actualizar el tracking. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    } else {
      console.log("***************************");
      console.log(newUserInfo.profileImages);
      await handleUploadImages();
      // const response = await fetch(`${API}/tracking`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-type': 'application/json',
      //     'Access-Control-Allow-Origin': '*'
      //   },
      //   body: JSON.stringify({
      //     receiverSendDate: sendDate,
      //     arriveDate: destinationArrivalDate,
      //     deliveryDate: recipientDeliveryDate,
      //     receivedBy,
      //     identification,
      //     shipment: shipmentId
      //   })
      // });
      // if (response.status === 201) {
      //   swal({
      //     title: "Tracking creado correctamente",
      //     icon: "success",
      //     button: "Aceptar"
      //   });
      //   setGuideNumberTracking('');
      //   setBroadcastDate('');
      //   setAddressee('');
      //   setCity('');
      //   setContent('');
      //   setSendDate(moment().format('YYYY-MM-DD HH:mm'));
      //   setDestinationArrivalDate(null);
      //   setRecipientDeliveryDate(null);
      //   setReceivedBy(null);
      //   setIdentification(null);
      //   setShipmentId('');
      //   setDisplayList('block');
      //   setDisplayTracking('none');
      // } else {
      //   swal({
      //     title: "Ocurrió un error al guardar la guía. Intente nuevamente",
      //     icon: "warning",
      //     button: "Aceptar"
      //   });
      // }
    }
  }

  useEffect(() => {
    if (hasRole(token, ['user'])) {
      getShipments(token.client.id);
    } else {
      getClients();
    }
  }, [])

  return (
    <div className={styles.shipment}>
      <div style={{ margin: "auto", padding: "10px" }} className="col-md-10">
        <div style={{ margin: "auto", padding: "10px" }} className="col-md-7">
          <form onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-6">
                <label>Desde</label>
                <input
                  type="text"
                  onChange={e => setFrom(e.target.value)}
                  value={from}
                  className="form-control"
                  placeholder="Ej: 2021-05-23 (YYYY-MM-DD)"
                />
              </div>
              <div class="col-sm-6">
                <label>Hasta</label>
                <input
                  type="text"
                  onChange={e => setTo(e.target.value)}
                  value={to}
                  className="form-control"
                  placeholder="Ej: 2021-05-23 (YYYY-MM-DD)"
                />
              </div>
              {/* {hasRole(token, ['admin']) && <div class="col-sm-12" style={{ margin: "0.5em auto", textAlign: "center" }}>
                <label>Cliente</label>
                <Autocomplete
                  onChange={(event, newValue) => {
                    // if (!newValue) {
                    //   setCanton(null)
                    // }
                    setClientId(newValue?.id);
                    //getClient(newValue?.id);
                  }}
                  options={clientList}
                  getOptionLabel={option => option.value}
                  style={{ marginTop: "0.3em" }}
                  renderInput={(params) =>
                    <TextField {...params} variant="outlined" />}
                />
              </div>} */}
            </div>
            <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
              Buscar
            </button>
          </form>
          {/* <ReactToPdf targetRef={ref} filename={"LISTADO-GUIAS-" + from + "-" + to} scale={0.65}>
            {({ toPdf }) => (
              <button id="button-pdf" style={{ margin: "2em auto", display: "none" }} className="btn btn-success btn-block" onClick={toPdf}>
                Generar PDF
              </button>
            )}
          </ReactToPdf> */}
          <ReactToPrint
            pageStyle={pageStyle}
            content={() => componentRef.current}
            trigger={() => <button id="button-pdf" style={{ margin: "0.5em auto", display: "block" }} className="btn btn-primary">Generar PDF</button>}
          />
        </div>
        <div style={{ margin: "auto", display: `${displayList}` }} className="col-md-10" ref={componentRef}>
          <div>
            <img src={logo} alt="LogoDef" style={{
              width: "400px",
              height: "87px",
              objectFit: "contain",
              padding: "0.75rem",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }} />
            <img src={direccion} alt="Direccion" style={{
              width: "400px",
              height: "51px",
              objectFit: "contain",
              padding: "0.75rem",
              marginTop: "-1em",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto"
            }} />
          </div>
          <div style={{ display: "flex" }}>
            <h5 style={{ color: "black" }}>Cliente: <b>{shipments[0]?.senderName}</b></h5>
            <h5 style={{ marginLeft: "auto", color: "black" }}>Fecha: <b>{creationDate}</b></h5>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>No.</th>
                <th>Nro Guía</th>
                <th>Destinatario</th>
                <th>Ciudad</th>
                <th>No. Envíos</th>
                <th>Descripción</th>
                <th>Departamento</th>
                <th>Usuario</th>
                {/* <th>Operación</th> */}
              </tr>
            </thead>
            <tbody>
              {shipments.map((shipment, index) => (
                <tr key={shipment.guideNumber}>
                  <td>{index + 1}</td>
                  <td>{shipment.guideNumber}</td>
                  <td>{shipment.receiver}</td>
                  <td>{shipment.city}</td>
                  <td>{shipment.numberPieces}</td>
                  <td>{shipment.contains}</td>
                  <td>{shipment.reference}</td>
                  <td>{shipment.agent}</td>
                  {/* <td><button className="btn btn-success btn-block" onClick={(e) => editTracking(shipment.guideNumber)}>Tracking</button></td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: "10em" }}>
            <div style={{ display: "flex" }}>
              <h5 style={{ color: "black" }}>---------------------------------</h5>
              <h5 style={{ marginLeft: "auto", color: "black" }}>---------------------------------</h5>
            </div>
            <div style={{ display: "flex" }}>
              <h5 style={{ marginLeft: "2em", color: "black" }}>Entregado por</h5>
              <h5 style={{ marginLeft: "auto", marginRight: "1.5em", color: "black" }}>Recibí Conforme</h5>
            </div>
          </div>
        </div>
        <div style={{ margin: "auto", display: `${displayTracking}` }} className="col-md-10">
          <h3 align="center">Información Tracking</h3>
          <form style={{ marginTop: "2em" }} onSubmit={handleSubmitTracking} onKeyDown={e => { handleKeyDown(e, handleSubmitTracking) }} className="card card-body">
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-4">
                <label>Nro guía</label>
                <input
                  type="text"
                  value={guideNumberTracking}
                  className="form-control"
                  disabled
                  autoFocus
                />
              </div>
              <div class="col-sm-4">
                <label>Fecha emisión</label>
                <input
                  type="text"
                  value={broadcastDate}
                  className="form-control"
                  disabled
                />
              </div>
              <div class="col-sm-4">
                <label>Destinatario</label>
                <input
                  type="text"
                  value={addressee}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-4">
                <label>Ciudad</label>
                <input
                  type="text"
                  value={city}
                  className="form-control"
                  disabled
                />
              </div>
              <div class="col-sm-4">
                <label>Contenido</label>
                <input
                  type="text"
                  value={content}
                  className="form-control"
                  disabled
                />
              </div>
              <div class="col-sm-4">
                <label>Fecha envío</label>
                <input
                  type="text"
                  onChange={e => setSendDate(e.target.value)}
                  value={sendDate}
                  className="form-control"
                  placeholder="Fecha envío destino"
                  disabled
                />
              </div>
            </div>
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-4">
                <label>Fecha llegada (destino)</label>
                <input
                  type="text"
                  onChange={e => setDestinationArrivalDate(e.target.value)}
                  value={destinationArrivalDate}
                  className="form-control"
                  placeholder="Ej: 2021-05-29 08:00 (YYYY-MM-DD hh:mm)"
                />
              </div>
              <div class="col-sm-4">
                <label>Fecha entrega (destinatario)</label>
                <input
                  type="text"
                  onChange={e => setRecipientDeliveryDate(e.target.value)}
                  value={recipientDeliveryDate}
                  className="form-control"
                  placeholder="Ej: 2021-05-29 08:00 (YYYY-MM-DD hh:mm)"
                />
              </div>
              <div class="col-sm-4">
                <label>Recibido por</label>
                <input
                  type="text"
                  onChange={e => setReceivedBy(e.target.value)}
                  value={receivedBy}
                  className="form-control"
                  placeholder="Recibido por"
                />
              </div>
            </div>
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-4">
                <label>Identificación de quien recibe</label>
                <input
                  type="text"
                  onChange={e => setIdentification(e.target.value)}
                  value={identification}
                  className="form-control"
                  placeholder="Identificación de quien recibe"
                />
              </div>
              <div class="col-sm-4">
                <label>Estado</label>
                <input
                  type="text"
                  value={(status === 'TRANSIT' || status === '') ? 'Tránsito' : "Entregado"}
                  className="form-control"
                  disabled
                />
              </div>
            </div>
            <FileUpload
              accept=".jpg,.png,.jpeg"
              label="Imágenes (Tamaño máximo por imágen: 0.5 MB)"
              multiple
              updateFilesCb={updateUploadedFiles}
            />
            <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
              {edition ? 'Actualizar' : 'Crear'}
            </button>
          </form>
        </div>
      </div>
    </div >
  )
}