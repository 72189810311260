import * as moment from 'moment';
import React, { useState } from 'react';
import swal from 'sweetalert';
import FileUpload from '../../components/FileUpload/file-upload.component';
import styles from './Tracking.module.scss';

const API = process.env.REACT_APP_API;

export const Tracking = () => {
  const [guideNumber, setGuideNumber] = useState('');
  const [guideNumberTracking, setGuideNumberTracking] = useState('');
  const [broadcastDate, setBroadcastDate] = useState('');
  const [addressee, setAddressee] = useState('');
  const [city, setCity] = useState('');
  const [content, setContent] = useState('');
  const [sendDate, setSendDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [destinationArrivalDate, setDestinationArrivalDate] = useState(null);
  const [recipientDeliveryDate, setRecipientDeliveryDate] = useState(null);
  const [receivedBy, setReceivedBy] = useState(null);
  const [identification, setIdentification] = useState(null);
  const [shipment, setShipment] = useState({});

  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: []
  });

  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  const handleSubmitTracking = async (e) => {
    e.preventDefault();
    const response = await fetch(`${API}/tracking`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        receiverSendDate: sendDate,
        arriveDate: destinationArrivalDate,
        deliveryDate: recipientDeliveryDate,
        receivedBy,
        identification,
        shipment: shipment.id
      })
    });
    if (response.status === 201) {
      swal({
        title: "Tracking creado correctamente",
        icon: "success",
        button: "Aceptar"
      });
      setGuideNumberTracking('');
      setBroadcastDate('');
      setAddressee('');
      setCity('');
      setContent('');
      setSendDate(moment().format('YYYY-MM-DD HH:mm'));
      setDestinationArrivalDate(null);
      setRecipientDeliveryDate(null);
      setReceivedBy(null);
      setIdentification(null);
      setShipment({});
    } else if (response.status === 500) {
      swal({
        title: "Ya existe un tracking asociado a esta guía, si desea actualizar la información vaya al menú Seguimiento tracking",
        icon: "warning",
        button: "Aceptar"
      });
    } else {
      swal({
        title: "Ocurrió un error al guardar la guía. Intente nuevamente",
        icon: "warning",
        button: "Aceptar"
      });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API}/shipment/client/by-guide?guideNumber=${guideNumber}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*'
        })
      });
      const data = await response.json();
      if (response.status === 200) {
        setGuideNumberTracking(data.guideNumber);
        setBroadcastDate(data.receptionsDate);
        setAddressee(data.receiver);
        setCity(data.city);
        setContent(data.contains);
        setShipment(data);
        setGuideNumber('');
      } else {
        setGuideNumber('');
        swal({
          title: "Ocurrió un error al obtener la guía. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
    catch {
      setGuideNumber('');
      swal({
        title: "No existe información para el número de guia ingresado",
        icon: "warning",
        button: "Aceptar"
      });
    }
  }

  return (
    <div className={styles.tracking}>
      <div style={{ margin: "auto" }} className="col-md-4">
        <h2>Ingrese el Número de Guía</h2>
        <form onSubmit={handleSubmit} className="card card-body">
          <input
            type="text"
            onChange={e => setGuideNumber(e.target.value)}
            value={guideNumber}
            className="form-control"
            placeholder="Número de guía"
            autoFocus
          />
          <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
            Buscar
        </button>
        </form>
      </div>
      <form style={{ marginTop: "2em" }} onSubmit={handleSubmitTracking} className="card card-body">
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Nro guía</label>
            <input
              type="text"
              value={guideNumberTracking}
              className="form-control"
              disabled
              autoFocus
            />
          </div>
          <div class="col-sm-4">
            <label>Fecha emisión</label>
            <input
              type="text"
              value={broadcastDate}
              className="form-control"
              disabled
            />
          </div>
          <div class="col-sm-4">
            <label>Destinatario</label>
            <input
              type="text"
              value={addressee}
              className="form-control"
              disabled
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Ciudad</label>
            <input
              type="text"
              value={city}
              className="form-control"
              disabled
            />
          </div>
          <div class="col-sm-4">
            <label>Contenido</label>
            <input
              type="text"
              value={content}
              className="form-control"
              disabled
            />
          </div>
          <div class="col-sm-4">
            <label>Fecha envío</label>
            <input
              type="text"
              onChange={e => setSendDate(e.target.value)}
              value={sendDate}
              className="form-control"
              placeholder="Fecha envío destino"
              disabled
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Fecha llegada (destino)</label>
            <input
              type="text"
              onChange={e => setDestinationArrivalDate(e.target.value)}
              value={destinationArrivalDate}
              className="form-control"
              placeholder="Ej: 2021-05-29 08:00 (YYYY-MM-DD hh:mm)"
            />
          </div>
          <div class="col-sm-4">
            <label>Fecha entrega (destinatario)</label>
            <input
              type="text"
              onChange={e => setRecipientDeliveryDate(e.target.value)}
              value={recipientDeliveryDate}
              className="form-control"
              placeholder="Ej: 2021-05-29 08:00 (YYYY-MM-DD hh:mm)"
            />
          </div>
          <div class="col-sm-4">
            <label>Recibido por</label>
            <input
              type="text"
              onChange={e => setReceivedBy(e.target.value)}
              value={receivedBy}
              className="form-control"
              placeholder="Recibido por"
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Identificación de quien recibe</label>
            <input
              type="text"
              onChange={e => setIdentification(e.target.value)}
              value={identification}
              className="form-control"
              placeholder="Identificación de quien recibe"
            />
          </div>
        </div>
        <FileUpload
          accept=".jpg,.png,.jpeg"
          label="Imágenes"
          multiple
          updateFilesCb={updateUploadedFiles}
        />
        <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
          Crear
          </button>
      </form>
    </div>
  )
}