import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';
import styles from './Guide.module.scss';

const API = process.env.REACT_APP_API;

export const Guide = () => {

  const ref = React.createRef();

  const pageStyle = `{ size: 1.5in 2in}`;
  const componentRef = useRef();

  const [guideNumber, setGuideNumber] = useState('');
  const [shipment, setShipment] = useState({});
  const [displayStatus, setDisplayStatus] = useState('none');
  const [displayStatusAuxTables, setDisplayStatusAuxTables] = useState('none');

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API}/shipment/client/by-guide?guideNumber=${guideNumber}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*'
        })
      });
      const data = await response.json();
      if (response.status === 200) {
        setShipment(data);
        setDisplayStatus('block');
        setGuideNumber('');
      } else {
        setDisplayStatus('none');
        setGuideNumber('');
        swal({
          title: "Ocurrió un error al obtener la guía. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
    catch {
      setDisplayStatus('none');
      setGuideNumber('');
      swal({
        title: "No existe información para el número de guia ingresado",
        icon: "warning",
        button: "Aceptar"
      });
    }
  }

  const generatePdf = async () => {
    setDisplayStatusAuxTables('block');
    document.getElementById('button-pdf').click();
    await sleep(5);
    setDisplayStatusAuxTables('none');
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className={styles.guide}>
        <h2>Información Estado de la Guía</h2>
        <form onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
          <input
            type="text"
            onChange={e => setGuideNumber(e.target.value)}
            value={guideNumber}
            className="form-control"
            placeholder="Número de guía"
            autoFocus
          />
          <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
            Buscar
          </button>
        </form>
      </div>
      <ReactToPrint
        pageStyle={pageStyle}
        content={() => componentRef.current}
        trigger={() => <button id="button-pdf" style={{ margin: "0.5em auto", display: "none" }} className="btn btn-primary">Generar PDF</button>}
      />
      <button style={{ margin: "auto", display: `${displayStatus}` }} className="btn btn-success btn-block" onClick={() => { generatePdf() }}>
        Generar PDF
          </button>
      <div style={{ margin: "auto", padding: "20px", marginTop: "20px", display: `${displayStatus}`, backgroundColor: "white" }} className="col-md-10" ref={componentRef}>
        <h7>Nro guía: <b>{shipment.guideNumber}</b></h7>
        <table size="sm" style={{ fontSize: "15px", width: "100%", border: "1px solid black" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>Remitente</b></td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3"><b>Destinatario</b></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6">{shipment.senderName}</td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3">{shipment.receiver}</td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.senderIdentification}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment?.client?.phone}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.receiverIdentification}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment.receiverPhone}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment?.client?.province}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment?.client?.location}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment?.client?.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment.province}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment.canton}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="6" style={{ border: "1px solid black" }}><label><b>Dirección remitente</b></label><br /><label>{shipment.senderAddress}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Dirección destinatario</b></label><br /><label>{shipment.receiverAddress}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment?.client?.reference}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Código  Postal</b></label><br /><label>{shipment?.client?.postalCode}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment.reference}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment?.client?.email}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Lote</b></label><br /><label>{shipment.guideNumber}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment.email}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Código Postal</b></label><br /><label>{shipment.postalCode}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>DETALLE DEL ENVIO</b></td>
              <td colspan="3" style={{ border: "1px solid black" }} ><label><b>Recibe</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Pzs</b></label><br /><label>{shipment.numberPieces}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Contenido</b></label><br /><label>{shipment.contains}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Kg.</b></label><br /><label>{shipment.itemWeight}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>P. Vol.</b></label><br /><label>{shipment.otherValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Declarado</b></label><br /><label>{shipment.declaredValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Seguro</b></label><br /><label>{shipment.insurancePremium}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Fecha</b></label><br /><label></label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Firma</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Usuario: </b>{shipment.agent}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Fecha: </b>{shipment.receptionsDate}</label></td>
              <td style={{ textAlign: "center", visibility: "false", background: "#A9A9A9" }} colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}