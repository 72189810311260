import React, { useEffect, useState } from 'react';
import styles from './User.module.scss'
import swal from 'sweetalert';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Modal, Button } from 'react-bootstrap';

const API = process.env.REACT_APP_API;

export const User = () => {

  const [editing, setEditing] = useState(false);
  const [id, setId] = useState('');

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [rolList, setRolList] = useState([]);
  const [rol, setRol] = useState(null);
  const [client, setClient] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [users, setUsers] = useState([]);

  //Modal
  const [show, setShow] = useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  }
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  }

  const getRoles = async () => {
    const response = [{id: "user", value: "Usuario"}, {id: "admin", value: "Administrador"}]
    setRolList(response);
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const clientList = data.map((client) => { return { id: client.id, value: client.name } })
    setClientList(clientList);
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editing) {
      const response = await fetch(`${API}/auth/user`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          name: userName,
          password,
          roles: rol?.id ? [rol.id] : null,
          clientId: client?.id ?? null,
        })
      });
      if (response.status === 201) {
        swal({
          title: "Usuario creado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setUserName('');
        setPassword('');
        setRol(null);
        setClient(null);
        getUsers();
      } else {
        swal({
          title: "Ocurrió un error al guardar cliente. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
    else {
      const response = await fetch(`${API}/auth/user/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          name: userName,
          password,
          roles: rol?.id ? [rol.id] : null,
          clientId: client?.id ?? null,
        })
      });
      if (response.status === 200) {
        swal({
          title: "Usuario actualizado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setUserName('');
        setPassword('');
        setRol(null);
        setClient(null);
        getUsers();
        setEditing(false);
      } else {
        swal({
          title: "Ocurrió un error al actualizar usuario. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
  }

  const getUsers = async () => {
    const response = await fetch(`${API}/auth/users`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setUsers(data);
  }

  const deleteUser = async (id) => {
    const userResponse = window.confirm('Está seguro de eliminar el usuario?');
    if (userResponse) {
      const response = await fetch(`${API}/auth/user/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      if (response.status === 200) {
        swal({
          title: "Usuario eliminado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        await getUsers();
      } else {
        swal({
          title: "Ocurrió un error al eliminar el usuario. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
  }

  const editUser = async (id) => {
    const response = await fetch(`${API}/auth/user/${id}`);
    const data = await response.json();
    setEditing(true);

    setUserName(data.name);
    setPassword(data.password);
    setRol(getRolDefault(data.roles[0]));
    setClient(getClientDefault(data.client.name));
    setId(data.id);
  }

  const updatePassword = async (e) => {
    const response = await fetch(`${API}/auth/user/password/${id}`, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        password: newPassword
      })
    });
    handleClose(e);
    setNewPassword('');
    if (response.status === 200) {
        setUserName('');
        setPassword('');
        setRol(null);
        setClient(null);
        getUsers();
        setEditing(false);
      swal({
        title: "Usuario eliminado correctamente",
        icon: "success",
        button: "Aceptar"
      });
      await getUsers();
    } else {
      swal({
        title: "Ocurrió un error al eliminar el usuario. Intente nuevamente",
        icon: "warning",
        button: "Aceptar"
      });
    }
  }

  const getClientDefault = (name) => {
    const clientCloneList = [...clientList];
    const result = clientCloneList.filter((client) => client.value === name);
    return result[0];
  }

  const getRolDefault = (name) => {
    const rolCloneList = [...rolList];
    const result = rolCloneList.filter((rol) => rol.id === name);
    return result[0];
  }

  useEffect(() => {
    getUsers();
    getRoles();
    getClients();
  }, [])

  return (
    <div className={styles.user}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Ingrese la nueva contraseña del usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Contraseña</label>
            <input
              type="password"
              onChange={e => setNewPassword(e.target.value)}
              value={newPassword}
              className="form-control"
              placeholder="Contraseña"
            />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={updatePassword}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <h3 align="center">Datos del usuario</h3>
      <form onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-3">
            <label>Usuario</label>
            <input
              type="text"
              onChange={e => setUserName(e.target.value)}
              value={userName}
              className="form-control"
              placeholder="Nombre de usuario"
            />
          </div>
          <div class="col-sm-3" style={{display: `${editing ? 'none' : 'block'}`}}>
            <label>Contraseña</label>
            <input
              type="password"
              onChange={e => setPassword(e.target.value)}
              value={password}
              className="form-control"
              placeholder="Contraseña"
            />
          </div>
          <div class="col-sm-3">
            <label>Cliente</label>
            <Autocomplete
              disabled={editing ? true : false}
              value={client}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setClient(null)
                } else {
                  setClient({ id: newValue?.id, value: newValue?.value });
                }
              }}
              options={clientList}
              getOptionLabel={option => option.value}
              style={{ marginTop: "0.3em" }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />}
            />
          </div>
          <div class="col-sm-3">
            <label>Rol</label>
            <Autocomplete
              value={rol}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setRol(null)
                } else {
                  setRol({ id: newValue?.id, value: newValue?.value });
                }
              }}
              options={rolList}
              getOptionLabel={option => option.value}
              style={{ marginTop: "0.3em" }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />}
            />
          </div>
          <div class="col-sm-3" style={{display: `${!editing ? 'none' : 'flex'}`, justifyContent: "center", alignItems: "center"}}>
            <button onClick={handleShow} style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
              Cambiar contraseña
            </button>
          </div>
        </div>
        <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
          {editing ? 'Actualizar' : 'Crear'}
        </button>
      </form>
      <h3 style={{ marginTop: "0.5em" }} align="center">Listado de usuarios</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Rol</th>
            <th>Cliente</th>
            <th>Operaciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.roles[0] === "user"? "Usuario" : "Administrador"}</td>
              <td>{user.client?.name}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm btn-block"
                  onClick={(e) => editUser(user.id)}
                  style={{ display: "block", width: "100%" }}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger btn-sm btn-block"
                  onClick={(e) => deleteUser(user.id)}
                  style={{ display: "block", width: "100%" }}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}