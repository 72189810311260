import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import swal from 'sweetalert';
import styles from './Shipment.module.scss';
import logo from './images/rotulo.png';
import useToken from '../../components/App/useToken';
import { hasRole } from '../../Auth';

const API = process.env.REACT_APP_API;

export const Shipment = () => {
  const { token, setToken } = useToken();
  const [senderIdentification, setSenderIdentification] = useState(token.client?.ruc ?? '');
  const [sender, setSender] = useState(token.client?.name ?? '');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [guideNumber, setGuideNumber] = useState("FGE17" + (Math.floor(Math.random() * 6000000) + 1000000));
  const [receiverIdentification, setReceiverIdentification] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [phone, setPhone] = useState(null);
  const [province, setProvince] = useState(null);
  const [canton, setCanton] = useState(null);
  const [city, setCity] = useState(null);
  const [address, setAddress] = useState(null);
  const [reference, setReference] = useState(null);
  const [email, setEmail] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [contains, setContains] = useState('');
  const [numberPieces, setNumberPieces] = useState(1);
  const [itemWeight, setItemWeight] = useState('');
  const [declaredValue, setDeclaredValue] = useState('');
  const [insurancePremium, setInsurancePremium] = useState('');
  const [shipment, setShipment] = useState({});
  const [displayStatus, setDisplayStatus] = useState('none');
  const [provinceList, setProvinceList] = useState([]);
  const [cantonList, setCantonList] = useState([]);
  const [agent, setAgent] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [clientList, setClientList] = useState([]);
  const ref = React.createRef();

  const options = [
    { id: 0, value: "foo" },
    { id: 1, value: "goo" },
  ];

  const getProvinces = async () => {
    const response = await fetch(`${API}/province`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const provinceList = data.map((province) => { return { id: province.code, value: province.name } })
    setProvinceList(provinceList);
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const clientList = data.map((client) => { return { id: client.id, value: client.name } })
    setClientList(clientList);
  }

  const getClient = async (clientId) => {
    const response = await fetch(`${API}/clients/${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setSenderIdentification(data.ruc);
    setSender(data.name);

  }

  const getCantons = async (provinceCode) => {
    if (!provinceCode) {
      setCantonList([]);
    } else {
      const response = await fetch(`${API}/canton/${provinceCode}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      const cantonList = data.map((canton) => { return { id: canton.code, value: canton.name } })
      setCantonList(cantonList);
    }
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableSubmit(true);
    const response = await fetch(`${API}/shipment`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        clientId: token.client.id,
        senderIdentification,
        sender,
        date,
        guideNumber,
        receiverIdentification,
        receiver,
        phone,
        province,
        canton,
        city,
        address,
        reference,
        email,
        postalCode,
        contains,
        agent,
        numberPieces: Number(numberPieces),
        itemWeight: Number(itemWeight),
        declaredValue: Number(declaredValue),
        insurancePremium: Number(insurancePremium)
      })
    });
    if (response.status === 201) {
      const shipmentResponse = await response.json();
      // swal({
      //   title: "Guía creada correctamente",
      //   icon: "success",
      //   button: "Aceptar"
      // });
      const shipmentByGuideNumber = await fetch(`${API}/shipment/client/by-guide?guideNumber=${shipmentResponse.guideNumber}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*'
        })
      });
      const data = await shipmentByGuideNumber.json();
      setShipment(data);
      setDisplayStatus('block');
      document.getElementById('button-pdf').click();
      setDate(moment().format('YYYY-MM-DD HH:mm'));
      setGuideNumber("FGE17" + (Math.floor(Math.random() * 6000) + 1000));
      setReceiverIdentification(null);
      setReceiver(null);
      setPhone(null);
      setProvince(null);
      setCanton(null);
      setCity(null);
      setAddress(null);
      setReference(null);
      setEmail(null);
      setPostalCode(null);
      setContains('');
      setNumberPieces(1);
      setItemWeight('');
      setDeclaredValue('');
      setInsurancePremium('');
      await sleep(50);
      setDisplayStatus('none');
      // localStorage.setItem('agent', agent);
      setDisableSubmit(false);
    } else {
      swal({
        title: "Ocurrió un error al guardar la guía. Intente nuevamente",
        icon: "warning",
        button: "Aceptar"
      });
      setDisableSubmit(false);
    }
  }

  const pageStyle = `{ size: 1.5in 2in}`;
  const componentRef = useRef();


  useEffect(() => {
    getProvinces();
    getClients();
    setAgent(token.name);
  }, [])


  return (
    <div className={styles.shipment}>
      <div style={{ margin: "auto", padding: "10px" }} className="col-md-10">
        <h3 align="center">ECOSERVICIOS</h3>
        <form id="shipment" onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
          <div class="col-sm-4">
            <h4 style={{ marginTop: "0.5em" }}>Usuario</h4>
            <input
              type="text"
              onChange={e => setAgent(e.target.value)}
              value={agent}
              className="form-control"
              placeholder="Usuario"
              disabled
              autoFocus
            />
          </div>
          {hasRole(token, ['admin']) && <div class="col-sm-4">
            <h4 style={{ marginTop: "0.5em" }}>Cliente</h4>
            <Autocomplete
              onChange={(event, newValue) => {
                // if (!newValue) {
                //   setCanton(null)
                // }
                // setProvince(newValue?.value);
                getClient(newValue?.id);
              }}
              options={clientList}
              getOptionLabel={option => option.value}
              style={{ marginTop: "0.3em" }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />}
            />
          </div>}
          <h4 style={{ marginTop: "0.5em" }}>Remitente</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Identificación</label>
              <input
                type="text"
                onChange={e => setSenderIdentification(e.target.value)}
                value={senderIdentification}
                className="form-control"
                placeholder="Identificación"
                autoFocus
                disabled
              />
            </div>
            <div class="col-sm-4">
              <label>Nombre</label>
              <input
                type="text"
                onChange={e => setSender(e.target.value)}
                value={sender}
                className="form-control"
                placeholder="Nombre"
                disabled
              />
            </div>
            <div class="col-sm-4">
              <label>Fecha</label>
              <input
                type="text"
                onChange={e => setDate(e.target.value)}
                value={date}
                className="form-control"
                placeholder="Ejemplo: 2021-05-23 09:00 (YYYY-MM-DD hh:mm)"
                disabled
              />
            </div>
          </div>
          <h4 style={{ marginTop: "0.5em" }}>Destinatario</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Identificación</label>
              <input
                type="text"
                onChange={e => setReceiverIdentification(e.target.value)}
                value={receiverIdentification}
                className="form-control"
                placeholder="Identificación"
              />
            </div>
            <div class="col-sm-4">
              <label>Nombre</label>
              <textarea value={receiver} onChange={e => setReceiver(e.target.value)} className="form-control" />
            </div>
            <div class="col-sm-4">
              <label>Teléfono</label>
              <input
                type="text"
                onChange={e => setPhone(e.target.value)}
                value={phone}
                className="form-control"
                placeholder="Teléfono"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Dirección</label>
              <textarea value={address} onChange={e => setAddress(e.target.value)} className="form-control" />
            </div>
            <div class="col-sm-4">
              <label>Provincia</label>
              <Autocomplete
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setCanton(null)
                  }
                  setProvince(newValue?.value);
                  getCantons(newValue?.id);
                }}
                options={provinceList}
                getOptionLabel={option => option.value}
                style={{ marginTop: "0.3em" }}
                renderInput={(params) =>
                  <TextField {...params} variant="outlined" />}
              />
            </div>
            <div class="col-sm-4">
              <label>Cantón</label>
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCanton(newValue?.value);
                  }
                }}
                options={cantonList}
                getOptionLabel={option => option.value}
                style={{ marginTop: "0.3em" }}
                renderInput={(params) =>
                  <TextField value={canton} {...params} variant="outlined" />}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Ciudad</label>
              <input
                type="text"
                onChange={e => setCity(e.target.value)}
                value={city}
                className="form-control"
                placeholder="Ciudad"
              />
            </div>
            <div class="col-sm-4">
              <label>Código postal</label>
              <input
                type="text"
                onChange={e => setPostalCode(e.target.value)}
                value={postalCode}
                className="form-control"
                placeholder="Código postal"
              />
            </div>
            <div class="col-sm-4">
              <label>Correo</label>
              <input
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                className="form-control"
                placeholder="Correo"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Departamento</label>
              <textarea value={reference} onChange={e => setReference(e.target.value)} className="form-control" />
            </div>
          </div>
          <h4 style={{ marginTop: "0.5em" }}>Detalle envío</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Contiene</label>
              <textarea value={contains} onChange={e => setContains(e.target.value)} className="form-control" />
              {/* <input
                type="textarea"
                onChange={e => setContains(e.target.value)}
                value={contains}
                className="form-control"
                placeholder="Contiene"
              /> */}
            </div>
            <div class="col-sm-4">
              <label>Número de piezas</label>
              <input
                type="text"
                onChange={e => setNumberPieces(e.target.value)}
                value={numberPieces}
                className="form-control"
                placeholder="Número de piezas"
              />
            </div>
            <div class="col-sm-4">
              <label>Peso en kg</label>
              <input
                type="text"
                onChange={e => setItemWeight(e.target.value)}
                value={itemWeight}
                className="form-control"
                placeholder="Peso en kg"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Declarado</label>
              <input
                type="text"
                onChange={e => setDeclaredValue(e.target.value)}
                value={declaredValue}
                className="form-control"
                placeholder="Declarado"
              />
            </div>
            <div class="col-sm-4">
              <label>Seguro</label>
              <input
                type="text"
                onChange={e => setInsurancePremium(e.target.value)}
                value={insurancePremium}
                className="form-control"
                placeholder="Seguro"
              />
            </div>
          </div>
          <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block" disabled={disableSubmit}>
            Crear
          </button>
        </form>
      </div>
      <ReactToPrint
        pageStyle={pageStyle}
        content={() => componentRef.current}
        trigger={() => <button id="button-pdf" style={{ margin: "0.5em auto", display: "none" }} className="btn btn-primary">Generar PDF</button>}
      />
      <div style={{ margin: "auto", display: `${displayStatus}` }} className="col-md-10" ref={componentRef}>
        <div style={{ display: "flex", height: "35px" }}>
          <img src={logo} alt="LogoDef" style={{
            width: "150px",
            height: "35px",
            objectFit: "contain",
            marginTop: "0px",
            marginBottom: "0px",
          }} />
          <h8 style={{ fontSize: "10px", marginTop: "0.9em" }}>Telf. (02) 3240349 Email: s.w.ecoservicios@gmail.com</h8>
          <h8 style={{ marginLeft: "auto", color: "black", fontSize: "10px", marginTop: "0.9em" }}>Nro guía: <b>{shipment.guideNumber}</b></h8>
        </div>
        <table size="sm" style={{ fontSize: "9.5px", width: "100%", border: "1px solid black", marginTop: "-6px" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>Remitente</b></td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3"><b>Destinatario</b></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6">{shipment.senderName}</td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3">{shipment.receiver}</td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.senderIdentification}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment?.client?.phone}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.receiverIdentification}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment.receiverPhone}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment?.client?.province}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment?.client?.location}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment?.client?.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment.province}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment.canton}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="6" style={{ border: "1px solid black" }}><label><b>Dirección remitente</b></label><br /><label>{shipment.senderAddress}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Dirección destinatario</b></label><br /><label>{shipment.receiverAddress}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment?.client?.reference}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Código  Postal</b></label><br /><label>{shipment?.client?.postalCode}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment.reference}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment?.client?.email}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Lote</b></label><br /><label>{shipment.guideNumber}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment.email}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Código Postal</b></label><br /><label>{shipment.postalCode}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>DETALLE DEL ENVIO</b></td>
              <td colspan="3" style={{ border: "1px solid black" }} ><label><b>Recibe</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Pzs</b></label><br /><label>{shipment.numberPieces}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Contenido</b></label><br /><label>{shipment.contains}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Kg.</b></label><br /><label>{shipment.itemWeight}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>P. Vol.</b></label><br /><label>{shipment.otherValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Declarado</b></label><br /><label>{shipment.declaredValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Seguro</b></label><br /><label>{shipment.insurancePremium}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Fecha</b></label><br /><label></label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Firma</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Usuario: </b>{shipment.agent}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Fecha: </b>{shipment.receptionsDate}</label></td>
              <td style={{ textAlign: "center", visibility: "false", background: "#A9A9A9" }} colspan="3"></td>
            </tr>
          </tbody>
        </table>
        <hr style={{ border: "1px dashed black", width: "100%", marginBottom: "0px", marginTop: "8px" }} />
        <div style={{ display: "flex", height: "35px" }}>
          <img src={logo} alt="LogoDef" style={{
            width: "150px",
            height: "35px",
            objectFit: "contain",
            marginTop: "0px",
            marginBottom: "0px",
          }} />
          <h8 style={{ fontSize: "10px", marginTop: "0.9em" }}>Telf. (02) 3240349 Email: s.w.ecoservicios@gmail.com</h8>
          <h8 style={{ marginLeft: "auto", color: "black", fontSize: "10px", marginTop: "0.9em" }}>Nro guía: <b>{shipment.guideNumber}</b></h8>
        </div>
        <table size="sm" style={{ fontSize: "9.5px", width: "100%", border: "1px solid black", marginTop: "1em", marginTop: "-7px" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>Remitente</b></td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3"><b>Destinatario</b></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6">{shipment.senderName}</td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3">{shipment.receiver}</td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.senderIdentification}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment?.client?.phone}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.receiverIdentification}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment.receiverPhone}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment?.client?.province}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment?.client?.location}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment?.client?.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment.province}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment.canton}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="6" style={{ border: "1px solid black" }}><label><b>Dirección remitente</b></label><br /><label>{shipment.senderAddress}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Dirección destinatario</b></label><br /><label>{shipment.receiverAddress}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment?.client?.reference}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Código  Postal</b></label><br /><label>{shipment?.client?.postalCode}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment.reference}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment?.client?.email}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Lote</b></label><br /><label>{shipment.guideNumber}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment.email}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Código Postal</b></label><br /><label>{shipment.postalCode}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>DETALLE DEL ENVIO</b></td>
              <td colspan="3" style={{ border: "1px solid black" }} ><label><b>Recibe</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Pzs</b></label><br /><label>{shipment.numberPieces}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Contenido</b></label><br /><label>{shipment.contains}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Kg.</b></label><br /><label>{shipment.itemWeight}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>P. Vol.</b></label><br /><label>{shipment.otherValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Declarado</b></label><br /><label>{shipment.declaredValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Seguro</b></label><br /><label>{shipment.insurancePremium}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Fecha</b></label><br /><label></label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Firma</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Usuario: </b>{shipment.agent}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Fecha: </b>{shipment.receptionsDate}</label></td>
              <td style={{ textAlign: "center", visibility: "false", background: "#A9A9A9" }} colspan="3"></td>
            </tr>
          </tbody>
        </table>
        <hr style={{ border: "1px dashed black", width: "100%", marginBottom: "0px", marginTop: "8px" }} />
        <div style={{ display: "flex", height: "35px" }}>
          <img src={logo} alt="LogoDef" style={{
            width: "150px",
            height: "35px",
            objectFit: "contain",
            marginTop: "0px",
            marginBottom: "0px",
          }} />
          <h8 style={{ fontSize: "10px", marginTop: "0.9em" }}>Telf. (02) 3240349 Email: s.w.ecoservicios@gmail.com</h8>
          <h8 style={{ marginLeft: "auto", color: "black", fontSize: "10px", marginTop: "0.9em" }}>Nro guía: <b>{shipment.guideNumber}</b></h8>
        </div>
        <table size="sm" style={{ fontSize: "9.5px", width: "100%", border: "1px solid black", marginTop: "-7px" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>Remitente</b></td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3"><b>Destinatario</b></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6">{shipment.senderName}</td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3">{shipment.receiver}</td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.senderIdentification}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment?.client?.phone}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.receiverIdentification}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment.receiverPhone}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment?.client?.province}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment?.client?.location}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment?.client?.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment.province}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment.canton}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="6" style={{ border: "1px solid black" }}><label><b>Dirección remitente</b></label><br /><label>{shipment.senderAddress}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Dirección destinatario</b></label><br /><label>{shipment.receiverAddress}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment?.client?.reference}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Código  Postal</b></label><br /><label>{shipment?.client?.postalCode}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment.reference}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment?.client?.email}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Lote</b></label><br /><label>{shipment.guideNumber}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment.email}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Código Postal</b></label><br /><label>{shipment.postalCode}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>DETALLE DEL ENVIO</b></td>
              <td colspan="3" style={{ border: "1px solid black" }} ><label><b>Recibe</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Pzs</b></label><br /><label>{shipment.numberPieces}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Contenido</b></label><br /><label>{shipment.contains}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Kg.</b></label><br /><label>{shipment.itemWeight}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>P. Vol.</b></label><br /><label>{shipment.otherValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Declarado</b></label><br /><label>{shipment.declaredValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Seguro</b></label><br /><label>{shipment.insurancePremium}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Fecha</b></label><br /><label></label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Firma</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Usuario: </b>{shipment.agent}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Fecha: </b>{shipment.receptionsDate}</label></td>
              <td style={{ textAlign: "center", visibility: "false", background: "#A9A9A9" }} colspan="3"></td>
            </tr>
          </tbody>
        </table>
        <hr style={{ border: "1px dashed black", width: "100%", marginBottom: "0px", marginTop: "8px" }} />
        <div style={{ display: "flex", height: "35px" }}>
          <img src={logo} alt="LogoDef" style={{
            width: "150px",
            height: "35px",
            objectFit: "contain",
            marginTop: "0px",
            marginBottom: "0px",
          }} />
          <h8 style={{ fontSize: "10px", marginTop: "0.9em" }}>Telf. (02) 3240349 Email: s.w.ecoservicios@gmail.com</h8>
          <h8 style={{ marginLeft: "auto", color: "black", fontSize: "10px", marginTop: "0.9em" }}>Nro guía: <b>{shipment.guideNumber}</b></h8>
        </div>
        <table size="sm" style={{ fontSize: "9.5px", width: "100%", border: "1px solid black", marginTop: "-7px" }}>
          <tbody style={{ border: "1px solid black" }}>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>Remitente</b></td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3"><b>Destinatario</b></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6">{shipment.senderName}</td>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="3">{shipment.receiver}</td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.senderIdentification}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment?.client?.phone}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Identificación</b></label><br /><label>{shipment.receiverIdentification}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Teléfono</b></label><br /><label>{shipment.receiverPhone}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment?.client?.province}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment?.client?.location}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment?.client?.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Provincia</b></label><br /><label>{shipment.province}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Ciudad</b></label><br /><label>{shipment.city}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Cantón</b></label><br /><label>{shipment.canton}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="6" style={{ border: "1px solid black" }}><label><b>Dirección remitente</b></label><br /><label>{shipment.senderAddress}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Dirección destinatario</b></label><br /><label>{shipment.receiverAddress}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment?.client?.reference}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Código  Postal</b></label><br /><label>{shipment?.client?.postalCode}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Referencia</b></label><br /><label>{shipment.reference}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="4" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment?.client?.email}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Lote</b></label><br /><label>{shipment.guideNumber}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Correo Electrónico</b></label><br /><label>{shipment.email}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Código Postal</b></label><br /><label>{shipment.postalCode}</label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td style={{ textAlign: "center", border: "1px solid black" }} colspan="6"><b>DETALLE DEL ENVIO</b></td>
              <td colspan="3" style={{ border: "1px solid black" }} ><label><b>Recibe</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Pzs</b></label><br /><label>{shipment.numberPieces}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Contenido</b></label><br /><label>{shipment.contains}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Kg.</b></label><br /><label>{shipment.itemWeight}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>P. Vol.</b></label><br /><label>{shipment.otherValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Declarado</b></label><br /><label>{shipment.declaredValue}</label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Seguro</b></label><br /><label>{shipment.insurancePremium}</label></td>
              <td colspan="2" style={{ border: "1px solid black" }}><label><b>Fecha</b></label><br /><label></label></td>
              <td colspan="1" style={{ border: "1px solid black" }}><label><b>Firma</b></label><br /><label></label></td>
            </tr>
            <tr style={{ border: "1px solid black" }}>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Usuario: </b>{shipment.agent}</label></td>
              <td colspan="3" style={{ border: "1px solid black" }}><label><b>Fecha: </b>{shipment.receptionsDate}</label></td>
              <td style={{ textAlign: "center", visibility: "false", background: "#A9A9A9" }} colspan="3"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div >
  )
}