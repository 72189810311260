import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './components/Login/Login';
import './App.css';
import NavbarBootstrap from './components/NavbarBootstrap/NavbarBootstrap';
import { Client } from './pages/client/Client';
import { Guide } from './pages/guide/Guide';
import { Shipment } from './pages/shipment';
import { ShipmentList } from './pages/shipment/list';
import { EcoserviciosShipmentList } from './pages/shipment/list-ecoservicios';
import { CreateTracking } from './pages/tracking/CreateTracking';
import { UpdateTracking } from './pages/tracking/List';
import { Tracking } from './pages/tracking/Tracking';
import useToken from './components/App/useToken';
import { hasRole } from './Auth';
import { UpdateShipment } from './pages/shipment/update';
import { User } from './pages/user/User';

function App() {
  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <Router>
      <NavbarBootstrap />
      <Switch>
        {hasRole(token, ['admin', 'user']) && <Route path='/' exact component={Guide}></Route>}
        {hasRole(token, ['admin', 'user']) && <Route path='/search' exact component={Guide}></Route>}
        {hasRole(token, ['admin', 'user']) && <Route path='/create' exact component={Shipment}></Route>}
        {hasRole(token, ['admin', 'user']) && <Route path='/list' exact component={ShipmentList}></Route>}
        {hasRole(token, ['admin', 'user']) && <Route path='/tracking-update' exact component={UpdateTracking}></Route>}
        {hasRole(token, ['admin', 'user']) && <Route path='/shipment-update' exact component={UpdateShipment}></Route>}
        {hasRole(token, ['admin']) && <Route path='/list-ecoservicios' exact component={EcoserviciosShipmentList}></Route>}
        {hasRole(token, ['admin']) && <Route path='/client' exact component={Client}></Route>}
        {hasRole(token, ['admin']) && <Route path='/tracking-create' exact component={Tracking}></Route>}
        {hasRole(token, ['admin']) && <Route path='/tracking-list' exact component={CreateTracking}></Route>}
        {hasRole(token, ['admin']) && <Route path='/user' exact component={User}></Route>}
      </Switch>
    </Router>
  );
}

export default App;
