import jwt_decode from "jwt-decode";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import swal from 'sweetalert';
import './Login.scss';
import logo from './images/logoecoDEF.png';

const API = process.env.REACT_APP_API;

async function loginUser(credentials) {
  const response = await fetch(`${API}/auth/login`, {
    method: 'POST',
    headers: new Headers({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(credentials)
  });
  if (response.status === 201) {
    const data = await response.json();
    return data.accessToken;
  } else {
    swal({
      title: "Usuario o contraseña incorrectas. Intente nuevamente",
      icon: "warning",
      button: "Aceptar"
    });
    return null;
  }
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      name: username,
      password
    });
    if (token) {
      const result = jwt_decode(token);
      setToken(result);
    }
  }

  return (
    <div className="login-wrapper">
      <form className="m-login-form" onSubmit={handleSubmit}>
        <img src={logo} alt="LogoDef" style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          marginTop: "0px",
          marginBottom: "0px",
        }} />
        <input type="text" placeholder="Usuario" onChange={e => setUserName(e.target.value)}/>
        <input type="password" placeholder="Contraseña" onChange={e => setPassword(e.target.value)}/>
        {/* <span>
					Please make sure your password contains a letter, number and a special character.
				</span> */}
        <input type="submit" value="Login" />
        <br/>
        <br/>
        {/* <a>Developed by </a><a href="https://www.linkedin.com/in/alex-pinaida-simba%C3%B1a-880b10146/" target="_blank">Ing. Alex Pinaida</a> */}
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};