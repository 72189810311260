import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { hasRole } from '../../Auth';
import useToken from '../../components/App/useToken';
import styles from './Shipment.module.scss';
import './styles.css';
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';

const API = process.env.REACT_APP_API;

export const UpdateShipment = () => {
  const { token, setToken } = useToken();
  const [senderIdentification, setSenderIdentification] = useState(token.client?.ruc ?? '');
  const [sender, setSender] = useState(token.client?.name ?? '');
  const [date, setDate] = useState(null);
  const [guideNumber, setGuideNumber] = useState("FGE17" + (Math.floor(Math.random() * 6000000) + 1000000));
  const [receiverIdentification, setReceiverIdentification] = useState('');
  const [receiver, setReceiver] = useState('');
  const [phone, setPhone] = useState('');
  const [province, setProvince] = useState(null);
  const [canton, setCanton] = useState(null);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [reference, setReference] = useState('');
  const [email, setEmail] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [contains, setContains] = useState('');
  const [numberPieces, setNumberPieces] = useState('');
  const [itemWeight, setItemWeight] = useState('');
  const [declaredValue, setDeclaredValue] = useState('');
  const [insurancePremium, setInsurancePremium] = useState('');
  const [shipment, setShipment] = useState({});
  const [displayStatus, setDisplayStatus] = useState('none');
  const [provinceList, setProvinceList] = useState([]);
  const [cantonList, setCantonList] = useState([]);
  const [agent, setAgent] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientId, setClientId] = useState('');
  const [client, setClient] = useState('');
  const [from, setFrom] = useState(moment().format('YYYY-MM-DD'));
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
  const [shipments, setShipments] = useState([]);
  const [shipmentId, setShipmentId] = useState('');
  const [creationDate, setCreationDate] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const pageStyle = `{ size: 1.5in 2in}`;
  const componentRef = useRef();

  const [trackingList, setTrackingList] = useState([]);
  const [images, setImages] = useState([]);

  const searchByGuideNumber = async (e) => {
    if (guideNumber === '') {
      e.preventDefault();
      getTrackingList(token.client?.id ?? clientId);
    } else {
      e.preventDefault();
      const response = await fetch(`${API}/tracking/guide/${guideNumber}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      setTrackingList(data);
      setGuideNumber('');
    }
  }

  const getTrackingList = async (clientId) => {
    const response = await fetch(`${API}/tracking?clientId=${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setTrackingList(data);
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const clientList = data.map((client) => { return { id: client.id, value: client.name } })
    setClientList(clientList);
  }

  const getClient = async (clientId) => {
    const response = await fetch(`${API}/clients/${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setSenderIdentification(data.ruc);
    setSender(data.name);
    console.log(data);
  }

  const getCantons = async (provinceCode) => {
    if (!provinceCode) {
      setCantonList([]);
    } else {
      const response = await fetch(`${API}/canton/${provinceCode}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      const cantonList = data.map((canton) => { return { id: canton.code, value: canton.name } })
      setCantonList(cantonList);
      return cantonList;
    }
  }

  const getCantonsInitials = async () => {
    const response = await fetch(`${API}/canton`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const cantonList = data.map((canton) => { return { id: canton.code, value: canton.name } })
    setCantonList(cantonList);
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const getShipments = async (clientId) => {
    const response = await fetch(`${API}/shipment?clientId=${clientId}`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setShipments(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (from === '' && to === '') {
      getShipments(token.client?.id ?? clientId);
    } else {
      const response = await fetch(`${API}/shipment/client/by-dates?from=${from}&to=${to}&clientId=${token.client?.id ?? clientId}`, {
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      const data = await response.json();
      setShipments(data);
      setFrom(moment().format('YYYY-MM-DD'));
      setTo(moment().format('YYYY-MM-DD'));
      setCreationDate(moment().format('YYYY-MM-DD HH:mm'));
    }
  }

  const editShipment = async (id) => {
    const response = await fetch(`${API}/shipment/${id}`);
    const data = await response.json();
    setDate(data.receptionsDate);
    setSenderIdentification(data.client.ruc);
    setSender(data.client.name);
    setReceiverIdentification(data.receiverIdentification);
    setReceiver(data.receiver);
    setPhone(data.receiverPhone);
    setProvince(getProvinceDefault(data.province));
    setCanton(await getCantonDefault(data.canton, data.province));
    setCity(data.city);
    setAddress(data.receiverAddress);
    setReference(data.reference);
    setEmail(data.email);
    setPostalCode(data.postalCode);
    setContains(data.contains);
    setNumberPieces(data.numberPieces);
    setItemWeight(data.itemWeight);
    setDeclaredValue(data.declaredValue);
    setInsurancePremium(data.insurancePremium);
    setAgent(data.agent);
    setShipmentId(id);
  }

  const handleSubmitShipment = async (e) => {
    e.preventDefault();
    if (shipmentId) {
      const response = await fetch(`${API}/shipment/${shipmentId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          senderIdentification,
          sender,
          receiverIdentification,
          receiver,
          phone,
          province: province?.value ?? '',
          canton: canton?.value ?? '',
          city,
          address,
          reference,
          email,
          postalCode,
          contains,
          numberPieces,
          itemWeight: Number(itemWeight),
          declaredValue: Number(declaredValue),
          insurancePremium: Number(insurancePremium),
          agent
        })
      });
      if (response.status === 200) {
        swal({
          title: "Guía actualizado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setDate('');
        setSenderIdentification('');
        setSender('');
        setReceiverIdentification('');
        setReceiver('');
        setPhone('');
        setProvince(null);
        setCanton(null);
        setCity('');
        setAddress('');
        setReference('');
        setEmail('');
        setPostalCode('');
        setContains('');
        setNumberPieces('');
        setItemWeight('');
        setDeclaredValue('');
        setInsurancePremium('');
        setAgent('');
        setShipmentId(null);
        getShipments(token.client?.id ?? clientId);
      } else {
        swal({
          title: "Ocurrió un error al actualizar la guía. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    } else {
      swal({
        title: "No ha seleccionado ningúna guía para actualziar",
        icon: "warning",
        button: "Aceptar"
      });
    }
  }

  const cancelShipment = async (id) => {
    const userResponse = window.confirm('Está seguro de anular la guía?');
    if (userResponse) {
      const response = await fetch(`${API}/shipment/cancel/${id}`, {
        method: 'PUT',
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      if (response.status === 200) {
        swal({
          title: "Guía anulada correctamente",
          icon: "success",
          button: "Aceptar"
        });
        await getShipments(token.client?.id ?? clientId);
      } else {
        swal({
          title: "Ocurrió un error al anular la guía. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
  }

  const getProvinces = async () => {
    const response = await fetch(`${API}/province`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const provinceList = data.map((province) => { return { id: province.code, value: province.name } })
    setProvinceList(provinceList);
  }

  const getDefault = (name) => {
    const clientCloneList = [...clientList];
    const result = clientCloneList.filter((client) => client.value === name);
    return result[0];
  }

  const getProvinceDefault = (name) => {
    const provinceCloneList = [...provinceList];
    const result = provinceCloneList.filter((province) => province.value === name);
    return result[0];
  }

  const getCantonDefault = async (name, provinceName) => {
    const provinceCloneList = [...provinceList];
    const result = provinceCloneList.filter((province) => province.value === provinceName);
    if (result.length > 0) {
      const cantonListResult = await getCantons(result[0].id);
      const cantonCloneList = [...cantonListResult];
      const cantonResult = cantonCloneList.filter((canton) => canton.value === name);
      return cantonResult[0];
    }
    //const getProvince = { ...province };
  }

  useEffect(async () => {
    if (hasRole(token, ['user'])) {
      await getShipments(token.client.id);
    } else {
      getProvinces();
      //getCantonsInitials();
      await getClients();
    }
  }, [])

  return (
    <div className={styles.shipmentUpdate}>
      <div style={{ margin: "auto", padding: "10px" }} className="col-md-10">
        <h3 align="center">Información guía</h3>
        <form id="shipment" onSubmit={handleSubmitShipment} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmitShipment) }}>
          <div class="col-sm-4">
            <h4 style={{ marginTop: "0.5em" }}>Usuario</h4>
            <input
              type="text"
              onChange={e => setAgent(e.target.value)}
              value={agent}
              className="form-control"
              placeholder="Usuario"
              disabled
              autoFocus
            />
          </div>
          <h4 style={{ marginTop: "0.5em" }}>Remitente</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Identificación</label>
              <input
                type="text"
                onChange={e => setSenderIdentification(e.target.value)}
                value={senderIdentification}
                className="form-control"
                placeholder="Identificación"
                autoFocus
                disabled
              />
            </div>
            <div class="col-sm-4">
              <label>Nombre</label>
              <input
                type="text"
                onChange={e => setSender(e.target.value)}
                value={sender}
                className="form-control"
                placeholder="Nombre"
                disabled
              />
            </div>
            <div class="col-sm-4">
              <label>Fecha</label>
              <input
                type="text"
                onChange={e => setDate(e.target.value)}
                value={date}
                className="form-control"
                placeholder="Ejemplo: 2021-05-23 09:00 (YYYY-MM-DD hh:mm)"
                disabled
              />
            </div>
          </div>
          <h4 style={{ marginTop: "0.5em" }}>Destinatario</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Identificación</label>
              <input
                type="text"
                onChange={e => setReceiverIdentification(e.target.value)}
                value={receiverIdentification}
                className="form-control"
                placeholder="Identificación"
              />
            </div>
            <div class="col-sm-4">
              <label>Nombre</label>
              <textarea value={receiver} onChange={e => setReceiver(e.target.value)} className="form-control" />
            </div>
            <div class="col-sm-4">
              <label>Teléfono</label>
              <input
                type="text"
                onChange={e => setPhone(e.target.value)}
                value={phone}
                className="form-control"
                placeholder="Teléfono"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Dirección</label>
              <textarea value={address} onChange={e => setAddress(e.target.value)} className="form-control" />
            </div>
            <div class="col-sm-4">
              <label>Provincia</label>
              <Autocomplete
                value={province}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setCanton(null)
                  }
                  setProvince({ id: newValue?.id, value: newValue?.value });
                  getCantons(newValue?.id);
                }}
                options={provinceList}
                getOptionLabel={option => option.value}
                style={{ marginTop: "0.3em" }}
                renderInput={(params) =>
                  <TextField {...params} variant="outlined" />}
              />
            </div>
            <div class="col-sm-4">
              <label>Cantón</label>
              <Autocomplete
                value={canton}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setCanton({ id: newValue?.id, value: newValue?.value });
                  }
                }}
                options={cantonList}
                getOptionLabel={option => option.value}
                style={{ marginTop: "0.3em" }}
                renderInput={(params) =>
                  <TextField value={canton} {...params} variant="outlined" />}
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Ciudad</label>
              <input
                type="text"
                onChange={e => setCity(e.target.value)}
                value={city}
                className="form-control"
                placeholder="Ciudad"
              />
            </div>
            <div class="col-sm-4">
              <label>Código postal</label>
              <input
                type="text"
                onChange={e => setPostalCode(e.target.value)}
                value={postalCode}
                className="form-control"
                placeholder="Código postal"
              />
            </div>
            <div class="col-sm-4">
              <label>Correo</label>
              <input
                type="email"
                onChange={e => setEmail(e.target.value)}
                value={email}
                className="form-control"
                placeholder="Correo"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Departamento</label>
              <textarea value={reference} onChange={e => setReference(e.target.value)} className="form-control" />
            </div>
          </div>
          <h4 style={{ marginTop: "0.5em" }}>Detalle envío</h4>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Contiene</label>
              <textarea value={contains} onChange={e => setContains(e.target.value)} className="form-control" />
              {/* <input
                type="textarea"
                onChange={e => setContains(e.target.value)}
                value={contains}
                className="form-control"
                placeholder="Contiene"
              /> */}
            </div>
            <div class="col-sm-4">
              <label>Número de piezas</label>
              <input
                type="text"
                onChange={e => setNumberPieces(e.target.value)}
                value={numberPieces}
                className="form-control"
                placeholder="Número de piezas"
              />
            </div>
            <div class="col-sm-4">
              <label>Peso en kg</label>
              <input
                type="text"
                onChange={e => setItemWeight(e.target.value)}
                value={itemWeight}
                className="form-control"
                placeholder="Peso en kg"
              />
            </div>
          </div>
          <div style={{ marginTop: "0.5em" }} className="form-group row">
            <div class="col-sm-4">
              <label>Declarado</label>
              <input
                type="text"
                onChange={e => setDeclaredValue(e.target.value)}
                value={declaredValue}
                className="form-control"
                placeholder="Declarado"
              />
            </div>
            <div class="col-sm-4">
              <label>Seguro</label>
              <input
                type="text"
                onChange={e => setInsurancePremium(e.target.value)}
                value={insurancePremium}
                className="form-control"
                placeholder="Seguro"
              />
            </div>
          </div>
          <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block" disabled={disableSubmit}>
            Actualizar
          </button>
        </form>
      </div>
      <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div style={{ margin: "auto", padding: "10px" }} className="col-md-6">
          <form onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
            <div style={{ marginTop: "0.5em" }} className="form-group row">
              <div class="col-sm-6">
                <label>Desde</label>
                <input
                  type="text"
                  onChange={e => setFrom(e.target.value)}
                  value={from}
                  className="form-control"
                  placeholder="Ej: 2021-05-23 (YYYY-MM-DD)"
                />
              </div>
              <div class="col-sm-6">
                <label>Hasta</label>
                <input
                  type="text"
                  onChange={e => setTo(e.target.value)}
                  value={to}
                  className="form-control"
                  placeholder="Ej: 2021-05-23 (YYYY-MM-DD)"
                />
              </div>
              {hasRole(token, ['admin']) && <div class="col-sm-6" style={{ margin: "0.5em auto", textAlign: "center" }}>
                <label>Cliente</label>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setClientId(newValue?.id);
                  }}
                  options={clientList}
                  getOptionLabel={option => option.value}
                  style={{ marginTop: "0.3em" }}
                  renderInput={(params) =>
                    <TextField {...params} variant="outlined" />}
                />
              </div>}
            </div>
            <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
              Buscar
            </button>
          </form>
          {/* <ReactToPrint
            pageStyle={pageStyle}
            content={() => componentRef.current}
            trigger={() => <button id="button-pdf" style={{ margin: "0.5em auto", display: "block" }} className="btn btn-primary">Generar PDF</button>}
          /> */}
        </div>
        <h3 style={{ marginTop: "0.5em" }} align="center">Listado de guías</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Nro Guía</th>
              <th>Destinatario</th>
              <th>Ciudad</th>
              <th>No. Envíos</th>
              <th>Descripción</th>
              <th>Departamento</th>
              <th>Usuario</th>
              <th>Operación</th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, index) => (
              <tr key={shipment.guideNumber}>
                <td>{index + 1}</td>
                <td>{shipment.guideNumber}</td>
                <td>{shipment.receiver}</td>
                <td>{shipment.city}</td>
                <td>{shipment.numberPieces}</td>
                <td>{shipment.contains}</td>
                <td>{shipment.reference}</td>
                <td>{shipment.agent}</td>
                <button
                  className="btn btn-primary btn-sm btn-block"
                  onClick={(e) => editShipment(shipment.id)}
                  style={{ display: "block", width: "100%", marginTop: "0.2em" }}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger btn-sm btn-block"
                  onClick={(e) => cancelShipment(shipment.id)}
                  style={{ display: "block", width: "100%", marginTop: "0.2em", marginBottom: "0.2em" }}
                >
                  Anular
                </button>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}