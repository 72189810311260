// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Shipment_shipment__cb_sf {\n  max-width: 2000px;\n  margin: 20px auto;\n  border: 1px solid #ccc;\n  padding: 40px;\n  border-radius: 4px;\n  background: white; }\n\n.Shipment_shipmentUpdate__16nCQ {\n  max-width: 2000px;\n  margin: 20px auto;\n  border: 1px solid #ccc;\n  padding: 40px;\n  border-radius: 4px;\n  background: white; }\n", "",{"version":3,"sources":["webpack://src/pages/shipment/Shipment.module.scss","webpack://src/pages/shipment/_Shipment.scss"],"names":[],"mappings":"AAEA;EACE,iBCDsB;EDEtB,iBAAiB;EACjB,sBCAqB;EDCrB,aAAoB;EACpB,kBCJgB;EDKhB,iBCDa,EAAA;;ADIf;EACE,iBCVsB;EDWtB,iBAAiB;EACjB,sBCTqB;EDUrB,aAAoB;EACpB,kBCbgB;EDchB,iBCVa,EAAA","sourcesContent":["@import 'Shipment';\n\n.shipment {\n  max-width: $invoiceMaxWidth;\n  margin: 20px auto;\n  border: 1px solid $tableBorderColor;\n  padding: 2 * $rhythm;\n  border-radius: $borderRadius;\n  background: $bgColor;\n}\n\n.shipmentUpdate {\n  max-width: $invoiceMaxWidth;\n  margin: 20px auto;\n  border: 1px solid $tableBorderColor;\n  padding: 2 * $rhythm;\n  border-radius: $borderRadius;\n  background: $bgColor;\n}","$rhythm: 20px;\n\n$invoiceMaxWidth: 2000px;\n$borderRadius: 4px;\n\n$tableBorderColor: #ccc;\n$boxBgColor: #eee;\n$bgColor: white;\n$addColor: darkgreen;\n$removeColor: darkred;\n\n%flex-center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shipment": "Shipment_shipment__cb_sf",
	"shipmentUpdate": "Shipment_shipmentUpdate__16nCQ"
};
export default ___CSS_LOADER_EXPORT___;
