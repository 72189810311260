import React, { useEffect, useState } from 'react';
import styles from './Client.module.scss'
import swal from 'sweetalert';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

const API = process.env.REACT_APP_API;

export const Client = () => {

  const [name, setName] = useState('');
  const [ruc, setRuc] = useState('');
  const [phone, setPhone] = useState(null);
  const [province, setProvince] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const [reference, setReference] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [department, setDepartment] = useState(null);
  const [abbreviation, setAbbreviation] = useState(null);
  const [provinceCode, setProvinceCode] = useState(null);
  const [editing, setEditing] = useState(false);
  const [id, setId] = useState('');

  const [clients, setClients] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cantonList, setCantonList] = useState([]);

  const getProvinces = async () => {
    const response = await fetch(`${API}/province`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const provinceList = data.map((province) => { return province.name })
    setProvinceList(provinceList);
  }

  const getCantons = async () => {
    const response = await fetch(`${API}/canton`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    const cantonList = data.map((canton) => { return canton.name })
    setCantonList(cantonList);
  }

  const handleKeyDown = (event, callback) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editing) {
      const response = await fetch(`${API}/clients`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          name,
          ruc,
          phone,
          province,
          city,
          location,
          address,
          reference,
          postalCode,
          email,
          department,
          abbreviation,
          provinceCode
        })
      });
      if (response.status === 201) {
        swal({
          title: "Cliente creado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setName('');
        setRuc('');
        setLocation('');
        setDepartment('');
        setPhone('');
        setProvince('');
        setCity('');
        setAddress('');
        setReference('');
        setEmail('');
        setPostalCode('');
        setAbbreviation('');
        setProvince('');
        getClients();
      } else {
        swal({
          title: "Ocurrió un error al guardar cliente. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
    else {
      const response = await fetch(`${API}/clients/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
          name,
          ruc,
          phone,
          province,
          city,
          location,
          address,
          reference,
          postalCode,
          email,
          department,
          abbreviation,
          provinceCode
        })
      });
      if (response.status === 200) {
        swal({
          title: "Cliente actualizado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        setName('');
        setRuc('');
        setLocation('');
        setDepartment('');
        setPhone('');
        setProvince('');
        setCity('');
        setAddress('');
        setReference('');
        setEmail('');
        setPostalCode('');
        setAbbreviation('');
        setProvince('');
        getClients();
        setEditing(false);
      } else {
        swal({
          title: "Ocurrió un error al actualizar cliente. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
  }

  const getClients = async () => {
    const response = await fetch(`${API}/clients`, {
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
      })
    });
    const data = await response.json();
    setClients(data);
  }

  const deleteClient = async (id) => {
    const userResponse = window.confirm('Está seguro de eliminar el usuario?');
    if (userResponse) {
      const response = await fetch(`${API}/clients/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Access-Control-Allow-Origin': '*',
        })
      });
      if (response.status === 200) {
        swal({
          title: "Cliente eliminado correctamente",
          icon: "success",
          button: "Aceptar"
        });
        await getClients();
      } else {
        swal({
          title: "Ocurrió un error al eliminar el cliente. Intente nuevamente",
          icon: "warning",
          button: "Aceptar"
        });
      }
    }
  }

  const editClient = async (id) => {
    const response = await fetch(`${API}/clients/${id}`);
    const data = await response.json();
    setEditing(true);
    setName(data.name);
    setRuc(data.ruc);
    setLocation(data.location);
    setDepartment(data.department);
    setPhone(data.phone);
    setProvince(data.province);
    setCity(data.city);
    setAddress(data.address);
    setReference(data.reference);
    setEmail(data.email);
    setPostalCode(data.postalCode);
    setAbbreviation(data.abbreviation);
    setId(data.id);
  }

  useEffect(() => {
    getClients();
    getProvinces();
    getCantons();
  }, [])

  return (
    <div className={styles.client}>
      <h3 align="center">Datos del cliente</h3>
      <form onSubmit={handleSubmit} className="card card-body" onKeyDown={e => { handleKeyDown(e, handleSubmit) }}>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Nombre</label>
            <input
              type="text"
              onChange={e => setName(e.target.value)}
              value={name}
              className="form-control"
              placeholder="Nombre"
              autoFocus
            />
          </div>
          <div class="col-sm-4">
            <label>RUC</label>
            <input
              type="text"
              onChange={e => setRuc(e.target.value)}
              value={ruc}
              className="form-control"
              placeholder="RUC"
            />
          </div>
          <div class="col-sm-4">
            <label>Teléfono</label>
            <input
              type="text"
              onChange={e => setPhone(e.target.value)}
              value={phone}
              className="form-control"
              placeholder="Teléfono"
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Provincia</label>
            <Autocomplete
              value={province}
              onChange={(event, newValue) => {
                setProvince(newValue);
              }}
              options={provinceList}
              style={{ marginTop: "0.3em" }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />}
            />
          </div>
          <div class="col-sm-4">
            <label>Cantón</label>
            <Autocomplete
              value={city}
              onChange={(event, newValue) => {
                setCity(newValue);
              }}
              options={cantonList}
              style={{ marginTop: "0.3em" }}
              renderInput={(params) =>
                <TextField {...params} variant="outlined" />}
            />
          </div>
          <div class="col-sm-4">
            <label>Ciudad</label>
            <input
              type="text"
              onChange={e => setLocation(e.target.value)}
              value={location}
              className="form-control"
              placeholder="Ciudad"
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Código provincia</label>
            <input
              type="text"
              onChange={e => setProvinceCode(e.target.value)}
              value={provinceCode}
              className="form-control"
              placeholder="Código provincia"
            />
          </div>
          <div class="col-sm-4">
            <label>Dirección</label>
            <input
              type="text"
              onChange={e => setAddress(e.target.value)}
              value={address}
              className="form-control"
              placeholder="Dirección"
            />
          </div>
          <div class="col-sm-4">
            <label>Referencia</label>
            <input
              type="text"
              onChange={e => setReference(e.target.value)}
              value={reference}
              className="form-control"
              placeholder="Referencia"
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
        <div class="col-sm-4">
            <label>Correo</label>
            <input
              type="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
              className="form-control"
              placeholder="Correo"
            />
          </div>
          <div class="col-sm-4">
            <label>Código postal</label>
            <input
              type="text"
              onChange={e => setPostalCode(e.target.value)}
              value={postalCode}
              className="form-control"
              placeholder="Código postal"
            />
          </div>
          <div class="col-sm-4">
            <label>Departamento</label>
            <input
              type="text"
              onChange={e => setDepartment(e.target.value)}
              value={department}
              className="form-control"
              placeholder="Departamento"
            />
          </div>
        </div>
        <div style={{ marginTop: "0.5em" }} className="form-group row">
          <div class="col-sm-4">
            <label>Abreviación</label>
            <input
              type="text"
              onChange={e => setAbbreviation(e.target.value)}
              value={abbreviation}
              className="form-control"
              placeholder="Abreviación"
            />
          </div>
        </div>
        <button style={{ marginTop: "0.5em" }} className="btn btn-primary btn-block">
          {editing ? 'Actualizar' : 'Crear'}
        </button>
      </form>
      <h3 style={{ marginTop: "0.5em" }} align="center">Listado de clientes</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>RUC</th>
            <th>Teléfono</th>
            <th>Provincia</th>
            <th>Ciudad</th>
            <th>Canton</th>
            <th>Dirección</th>
            <th>Referencia</th>
            <th>Departamento</th>
            <th>Email</th>
            <th>Abreviación</th>
            <th>Cod. Provincia</th>
            <th>Operaciones</th>
          </tr>
        </thead>
        <tbody>
          {clients.map(client => (
            <tr key={client.id}>
              <td>{client.name}</td>
              <td>{client.ruc}</td>
              <td>{client.phone}</td>
              <td>{client.province}</td>
              <td>{client.location}</td>
              <td>{client.city}</td>
              <td>{client.address}</td>
              <td>{client.reference}</td>
              <td>{client.department}</td>
              <td>{client.email}</td>
              <td>{client.abbreviation}</td>
              <td>{client.provinceCode}</td>
              <td>
                <button
                  className="btn btn-primary btn-sm btn-block"
                  onClick={(e) => editClient(client.id)}
                  style={{ display: "block", width: "100%" }}
                >
                  Editar
                </button>
                <button
                  className="btn btn-danger btn-sm btn-block"
                  onClick={(e) => deleteClient(client.id)}
                  style={{ display: "block", width: "100%" }}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}